import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeCtxProvider } from '../src/utils/context/themeCtx'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App'
const twentyFourHoursInMs = 1000 * 60 * 60 * 24

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
})

ReactDOM.render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<ThemeCtxProvider>
				<App />
			</ThemeCtxProvider>
		</QueryClientProvider>
	</BrowserRouter>,
	document.getElementById("root")
)
