import React, { useState } from 'react';
import {setLogger, useMutation, useQueryClient } from 'react-query'
import { Oval } from  'react-loader-spinner'
import { Icon } from '@mui/material';

import './saldo.scss';

import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Input from '../../components/input/Input'
import Teclado from '../../components/tecladoNumero/Teclado'
import Button from '../../components/button/Button'
import {enviaMensagem} from '../../api/saldo';

const Saldo = () => {
  const queryClient = useQueryClient()

  const [CPF, setCPF] = useState("");
  const [loader, setLoader] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [erro, setErro] = useState(false);

  const lidarEnviaMsg = () => {
    setLoader(true)
    getEnviaMensagem.mutate({"cpf":CPF, status_repasse: "Banco"})
  }

  const handleKeyPress = (cpf) => {
    cpf = cpf.replace(/\D/g, '');
    if (cpf.length > 11) {
      cpf = cpf.slice(0, 11);
    }

    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    setCPF(cpf)
  };

  const getEnviaMensagem =  useMutation(enviaMensagem, {
    onSuccess: (e) => {
      setLoader(false)
      if(e["status"] === 201){
        setSucesso(true)
        //alert("Consulta concluída! Pedimos que aguarde um momento que enviaremos seu saldo pelo numero do seu WhatsApp")
      }else{
        //alert(e.data.data)
        setErro(true)
      }
    },
    onError: (e) => {
      setLoader(false)
      setErro(true)
      //alert(e.response.data)
    }
    
  });

  return (
    <div className="saldo">
      <div className="homeCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"} />
        <Sidebar nomePagena={"Consulta de saldo"} height={"35px"}/>
        <div className="content">
          { loader == true &&
            <div className='loader'>
              <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
            </div>
          }
          <div className='titulo'>Por favor, insira seu CPF abaixo:</div>

          <div className='input-sado'>
            <Input
              type={"cpf"}
              name={"cpf-motorista"}
              value={CPF}
              onChange={setCPF}
              placeholder={"XXX.XXX.XXX-XX"}
            />
             <Teclado onKeyPress={handleKeyPress} />
          </div>

          <div className='teclado-saldo'>
           
          </div>

          <div className='botao-saldo'>
            < Button
              text='Confirmar'
              width='120px' 
              borderRadius='8px' 
              border='black' 
              background='rgb(66, 175, 60)' 
              color='white' 
              onClick={()=>{lidarEnviaMsg()}}
            /> 
          </div>

          { sucesso !== false &&
            <div className='info-avisos'>
              <div>
                <Icon style={{ fontSize: 100, color:"rgb(66, 175, 60)" }}>check_circle_outline</Icon>
              </div>
              <div  style={{ color:"rgb(66, 175, 60)" }}>
                Consulta Concluída! Seu saldo foi enviado para seu Whatsapp.
              </div>
            </div>
          }

          { erro !== false &&
            <div className='info-avisos'>
              <div>
                <Icon style={{ fontSize: 100, color:"#c84333" }}>highlight_off</Icon>
              </div>
              <div  style={{ color:"#c84333" }}>
              Ops! Seu CPF não foi encontrado no sistema. Confirme os numero ou por favor entre em contato com o Suporte.
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};


export default Saldo;
