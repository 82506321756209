import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import StorefrontIcon from '@mui/icons-material/Storefront';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Icon } from '@mui/material';
import { Oval } from  'react-loader-spinner'

import './home.scss';

import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Button from '../../components/button/Button';
import PopUpAlerta from '../../components/popUpAlerta/PopUpAlerta'
import PopUpConfirmaRecibo from '../../components/popUpVazio/PopUpVazio'

import { atualizaStatusRecibo, getReciboByID, getSaldoByID } from '../../api/recibos';
import { getRepasseByIdMotorista, sacaValorMotorista } from '../../api/repasses'

const Check = () => {
  const { id } = useParams();
  const [recibo, setRecibo] = useState({});
  const [recarregar, setRecarregar] = useState(0);
  const [saldoAtual, setSaldoAtual] = useState(0);
  const [repasseAgendado, setRepasseAgendado] = useState(0); 
  const [botaoSaque, setBotaoSaque] = useState(true); 

  const [popUpConfirmaRecibo, setPopUpConfirmaRecibo] = useState(false)
  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);
  const [loader, setLoader] = useState(false);

  const [selectedValue, setSelectedValue] = useState('');


  const opcoes=[
    {value:"Atual", name: "Saldo Atual", label: "Saldo Atual", saldo: saldoAtual},
    {value:"Agendado", name: "Repasse Agendado", label: "Repasse Agendado", saldo: repasseAgendado},
  ]

  useEffect(() => {
    
    const fetchRecibos = async () => {
      let res;
      let idMotorista;
      try {
        res = await getReciboByID(id);
        idMotorista = res?.data?.id;
        setRecibo(res.data);
      } catch (error) {
        console.error("Erro ao buscar recibo:", error);
      }
      try {
        if(idMotorista) {
          const saldo = await getSaldoByID(idMotorista);
          if(saldo?.data?.saldo) setSaldoAtual(saldo.data.saldo);
        }
      } catch (error) {
        console.error("Erro ao buscar saldo atual do motorista:", error);
      }
      try {
        if(idMotorista) {
          const saldoRepasse = await getRepasseByIdMotorista(idMotorista);
          if(saldoRepasse?.data[0].valor) setRepasseAgendado(saldoRepasse?.data[0].valor);
        }
      } catch (error) {
        console.error("Erro ao buscar repasse agendado do motorista:", error);
      }
    };
    fetchRecibos();
  }, [id, recarregar]);


  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedValue(event.target.value);
  };

  const handleConfirmaRecibo = () => {
    setBotaoSaque(false)
    setPopUpConfirmaRecibo(true);
  }

  const handleConfirmaSaque = async () => {
    setLoader(true)
    try{
      await sacaValorMotorista(id, recibo?.id, selectedValue, recibo?.valor)
    }catch(error){
      console.log("Erro ao sacar valor motorista");
    }
    setBotaoSaque(true)
    setPopUpConfirmaRecibo(false);
    setLoader(false)
    setRecarregar(!recarregar);
  }

  const handleCancelaSaque = async()=>{
    try{
      await atualizaStatusRecibo(id, "Cancelado");
    }catch(error){
      console.log("Erro ao atualizar status do recibo");
    }
    setPopUpConfirmaRecibo(false);
    setRecarregar(!recarregar);
  }

  return (
    <div className="home">
      <div className="homeCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"} />
        {recibo?.status_check==="Efetivado"? <Sidebar />: 
          <div className="sidebar-Aguardando">
            <div className="top">
              Confirmação Utilização Convênio
            </div>
          </div>
        }
        <div className="content" style={{ minHeight: "calc(100vh - 230px)",display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height:'100%'}}>
          {recibo?.status_check==="Aguardando"?
          <div className="content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height:"100%" }}>
              {loader == true &&
                <div className='loader'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                </div>
              }
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap',  justifyContent: 'center' }}>
                <p style={{display: 'flex', justifyContent: 'center', color:"rgb(66, 175, 60)" }} className='dinheiro'>{recibo?.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
              </div>
              <p style={{margin: 'auto', color: '#999999ff'}}>em</p>
              
              <div style={{ display: "flex", alignItems: "center", height: 'auto', marginTop: '10px', justifyContent: 'center' }}>
                <StorefrontIcon style={{ marginRight: "5px" }} />
                {recibo?.empresa}
              </div>
            </div>
            
            <div style={{ color: "#5a595c", display: 'flex', flexDirection:'column', justifyContent: 'center' }}>
              <p style={{margin: 'auto', color: '#444444ff'}}>{"Opções disponíveis para pagamento: "}</p>

              <div style={{width:'auto', justifyContent: 'center', display:'flex'}}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={handleChange}
                  >
                    {opcoes.map(({value, label, saldo}) => {
                      if(value === "Atual" && saldo > recibo?.valor){
                        return(
                          <div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop:"10px" }}>
                              <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                              <div style={{fontSize:"10px", color:"#f09226" }}> +R$ 2,50</div>
                            </div>
                            <div style={{top:"-14px", position:"relative"}}>
                              <div style={{fontSize:"13px", display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft:"30px" }}>
                                {saldo.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                              </div>
                            </div>
                          </div>
                        )
                      }else if(value === "Agendado"  && saldo > recibo?.valor){
                        return(
                          <div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop:"10px" }}>
                              <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                            </div>
                            <div style={{top:"-14px", position:"relative"}}>
                              <div style={{fontSize:"13px", display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft:"30px" }}>
                                {saldo.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                              </div>
                            </div>
                          </div>
                        )

                      }
                      <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                    })}
                  </RadioGroup>
                </FormControl>
              </div>

            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap',  justifyContent: 'center' }}>
             { botaoSaque === true &&
                <Button text='Confirmar'
                  width='120px' 
                  borderRadius='8px' 
                  border='black' 
                  background='rgb(66, 175, 60)' 
                  color='white' 
                  onClick={()=>handleConfirmaRecibo(selectedValue)}
                />
              }
              { botaoSaque === false &&
                <Button text='Confirmar'
                  width='120px' 
                  borderRadius='8px' 
                  border='black' 
                  background='rgb(66, 175, 60)' 
                  color='white' 
                  onClick={()=>{}}
                />
              }
              <Button text='Recusar'
                width='120px' 
                borderRadius='8px' 
                border='black' 
                background='#c84333' 
                color='white' 
                onClick={handleCancelaSaque}
              />
            </div>
            <div style={{height:"20%"}}>
            </div>

            <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
              {
                <div>
                  {conteudoPopUp}
                </div>
              }
            </PopUpAlerta>

            <PopUpConfirmaRecibo trigger={popUpConfirmaRecibo} setTrigger={setPopUpConfirmaRecibo} light={true} >
            

                <div style={{ color: "#5a595c" }}>
                  {selectedValue?
                    <>
                      <div style={{color: "#5a595c" , marginBottom:"10px"}}>
                        <p>
                          {"Você deseja confirmar o saque de R$" + 
                          recibo?.valor +
                          " de " +
                          opcoes.find(option => option.value === selectedValue).name +
                          "?"}</p>
                      </div>
                      <div>
                        < Button
                          text='Sim'
                          width='120px' 
                          borderRadius='8px' 
                          border='black' 
                          background='rgb(66, 175, 60)' 
                          color='white' 
                          onClick={handleConfirmaSaque}
                        /> 
                        < Button
                          text='Não'
                          width='120px' 
                          borderRadius='8px' 
                          border='black' 
                          background='#c84333' 
                          color='white' 
                          onClick={()=>{setPopUpConfirmaRecibo(false)}}
                        /> 
                      </div>
                    </>
                    :
                    <div style={{color: "#5a595c" , marginBottom:"10px"}}>
                      <p>Escolha uma opção válida.</p>
                    </div>
                    }
                </div>
              
            </PopUpConfirmaRecibo>
          
          </div>
          :
            <div style={{ width:"100%", height:"100%"}}>
              {recibo?.status_check==="Efetivado" &&
                <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%"}}>
                  <div style={{display:"flex", justifyContent:"flex-end", margin:"10px 20px 10px 20px",}}>
                    Status:
                    <div style={{ marginLeft:"5px", background: "rgb(66, 175, 60)", border: "1px solid rgb(66, 175, 60)", borderRadius:"5px", color:"white", width:"70px", justifyContent:"center", display:"flex"}}> 
                      Pago 
                    </div>
                  </div>
                  <div style={{display:"flex",flexDirection:"column", alignItems:"center", height:"100%", justifyContent:"center"}}>
                    <Icon sx={{ fontSize: 100, color:"rgb(66, 175, 60)" }}>done_all</Icon>
                    <div  style={{color:"rgb(66, 175, 60)", fontWeight: "bold", fontFamily: "sans-serif"}}> Pagamento confirmado.</div>
                  </div>

                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Check;
