/* SUMÁRIO
  getSolicitacaoByID: Procura solicitação por número de OS da entrega.
  registraRepasse: Registra um novo retorno de entrega.
  getRepassesDaEmpresa: Obtém todos os repasses de entrega de uma determinada empresa.
  atualizaStatusRepasse: Atualiza o status de um retorno de entrega.
*/

import axios from 'axios'

const baseURL = '/repasses'

const repassesApi = axios.create({
  baseURL: baseURL,
})

/**
 * Procura solicitação por número de OS da entrega.
 * @param {string} id - Número de OS da entrega.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getRepasseByID = async (id) => { 
  //console.log("idMotorista:", id)
  const res = await repassesApi.get('/getRepasseByID', {params: {dados: id}})
  return res
}

export const getRepasseByIdMotorista = async (id) => { 
  //console.log("idMotorista:", id)
  const res = await repassesApi.get('/getRepasseByIdMotorista', {params: {dados: id}})
  return res
}

export const getSaldoByID = async (id) => { 
  const res = await repassesApi.get('/getSaldoByID', {params: {dados: id}})
  return res
}


/**
 * Registra um novo retorno de entrega.
 * @param {Object} dados - Dados do retorno a ser registrado.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const registraRepasse = async (dados) => {
  const res = await repassesApi.post('/registraRepasse', {params: {dados: dados}})
  return res
}

/**
 * Obtém todos os repasses de entrega de uma determinada empresa.
 * @param {string} dados - ID da empresa.
 * @returns {Promise} - Promise que contém os dados dos repasses de entrega.
 */
export const getRepassesDaEmpresa = async (dados) => {
  const res = await repassesApi.get('/getRepassesDaEmpresa', {params: {dados: dados}})
  return res.data
}

export const getRepassesDoMotorista = async (dados) => {
  const res = await repassesApi.get('/getRepassesDoMotorista', {params: {dados: dados}})
  return res.data
}

export const getRepasses = async () => {
  const res = await repassesApi.get('/getRepasses')
  return res.data
}

export const sacaValorMotorista = async (idRecibo, idMotorista, metodo, valor) => {
  try {
    const res = await repassesApi.post('/sacaValorMotorista', {params: {idRecibo: idRecibo, id: idMotorista, metodo: metodo, valor: valor}})
    return res.data
  } catch (error) {
    console.error("Erro ao atualizar status de retorno:", error)
    throw error
  }
}


/**
 * Atualiza o status de um retorno de entrega.
 * @param {string} id - Id do retorno a ser atualizado.
 * @param {string} atualizadoPor - papel da pessoa que realizou a ultima atualização.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const atualizaStatusRepasse = async (id, atualizadoPor) => {
  try {
    const res = await repassesApi.post('/atualizaStatusRepasse', {params: {id: id, atualizadoPor: atualizadoPor}})
    return res.data
  } catch (error) {
    console.error("Erro ao atualizar status de retorno:", error)
    throw error
  }
}

/**
 * Procura solicitação por número de OS da entrega.
 * @param {string} id - Número de OS da entrega.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const atulizaRepasseMachine = async (id, os) => { 
  const res = await repassesApi.get('/atulizaRepasseMachine', {params: {id: id, os: os}})
  return res
}
