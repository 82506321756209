import * as React from 'react';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Typography from '@mui/material/Typography';

import { CardActionArea } from '@mui/material';

export default function CardPedido({ pedido, motorista, endereco, retorno, onClick }) {
  return (
    <Card sx={{ width: '95%', overflowY: 'hidden', marginBottom: '15px' }}>
      <CardActionArea onClick={onClick} sx={{ display: 'inline !important' }}>
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", height: 'auto' }}>
            <StorefrontIcon style={{ marginRight: "5px" }} />
            <Typography gutterBottom variant="p4" component="div" style={{ height: 'auto', overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px"  }}>
              {motorista}
            </Typography>

            <div style={{ height: "auto", marginLeft: "auto" }}>
              {retorno.dinheiro ?
                <Chip label={`Dinheiro`} variant="outlined" color="success" style={{ height: "auto", marginLeft: "auto", marginLeft: '2px' }} />
                :
                null
              }

              {retorno.maquininha ?
                <Chip label={`Maquininha`} variant="outlined" color="success" style={{ height: "auto", marginLeft: "auto", marginLeft: '2px' }} />
                :
                null
              }
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", height: "auto" }}>
            <PlaceOutlinedIcon fontSize="small" style={{ marginRight: "5px" }} />
            <Typography variant="body2" color="text.secondary" >
              {endereco}
            </Typography>
            <div style={{ height: "auto", marginLeft: "auto" }}>
              {retorno.dinheiro ?
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  R${retorno.valorDinheiro}
                </div>
                : null}
              {retorno.maquininha ?
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  R${retorno.valorMaquininha} ID: {retorno.idMaquininha}
                </div>
                : null}
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

