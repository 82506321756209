import React from 'react'
import './sidebar.scss'

const Sidebar = (props) => {
  const {
    nomePagena = "",
    height = "10px"
  } = props;

  return (
    <div className="sidebar">
      <div className="top" style={{height:height}}>
        { nomePagena !== "" &&
          nomePagena
        }
      </div>
    </div>
  );
};

export default Sidebar
