import React, { useState } from 'react';
import './teclado.scss'; // Arquivo CSS para estilos (opcional)

const NumericKeyboard = ({ onKeyPress }) => {
  const [inputValue, setInputValue] = useState('');

  const handleButtonClick = (value) => {
    const newValue = inputValue + value;
    setInputValue(newValue);

    // Chama a função passada por props com o novo valor
    if (onKeyPress) {
      onKeyPress(newValue);
    }
  };

  const handleClear = () => {
    setInputValue('');
    if (onKeyPress) {
      onKeyPress('');
    }
  };

  return (
    <div className="numeric-keyboard">
      <div className="keys">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
          <button
            key={num}
            onClick={() => handleButtonClick(num.toString())}
            className='button-teclado'
          >
            {num}
          </button>
        ))}
        <button className='button-teclado' onClick={handleClear}>Limpar</button>
      </div>
    </div>
  );
};

export default NumericKeyboard;
