/* SUMÁRIO
  getSolicitacaoByID: Procura solicitação por número de OS da entrega.
  registraRecibo: Registra um novo retorno de entrega.
  getRecibosDaEmpresa: Obtém todos os recibos de entrega de uma determinada empresa.
  atualizaStatusRecibo: Atualiza o status de um retorno de entrega.
*/

import axios from 'axios'

const baseURL = '/recibos'

const recibosApi = axios.create({
  baseURL: baseURL,
})

/**
 * Procura solicitação por número de OS da entrega.
 * @param {string} id - Número de OS da entrega.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getReciboByID = async (id) => { 
  const res = await recibosApi.get('/getReciboByID', {params: {dados: id}})
  return res
}

export const getSaldoByID = async (id) => { 
  const res = await recibosApi.get('/getSaldoByID', {params: {dados: id}})
  return res
}


/**
 * Registra um novo retorno de entrega.
 * @param {Object} dados - Dados do retorno a ser registrado.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const registraRecibo = async (dados) => {
  const res = await recibosApi.post('/registraRecibo', {params: {dados: dados}})
  return res
}

/**
 * Obtém todos os recibos de entrega de uma determinada empresa.
 * @param {string} dados - ID da empresa.
 * @returns {Promise} - Promise que contém os dados dos recibos de entrega.
 */
export const getRecibosDaEmpresa = async (dados) => {
  const res = await recibosApi.get('/getRecibosDaEmpresa', {params: {dados: dados}})
  return res.data
}

export const getRecibosDoMotorista = async (dados) => {
  const res = await recibosApi.get('/getRecibosDoMotorista', {params: {dados: dados}})
  return res.data
}

export const getRecibos = async () => {
  const res = await recibosApi.get('/getRecibos')
  return res.data
}

export const atualizaStatusRecibo = async (id, status_check) => {
  try {
    const res = await recibosApi.post('/atualizaStatusRecibo', {params: {id: id, status_check: status_check}})
    return res.data
  } catch (error) {
    console.error("Erro ao atualizar status de retorno:", error)
    throw error
  }
}

/**
 * Procura solicitação por número de OS da entrega.
 * @param {string} id - Número de OS da entrega.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const atulizaReciboMachine = async (id, os) => { 
  const res = await recibosApi.get('/atulizaReciboMachine', {params: {id: id, os: os}})
  return res
}
