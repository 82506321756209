import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, Route, Routes, useNavigate } from "react-router-dom";

import './navbar.scss'

import logo from "./logo.png";

const NavBar = (prop) => {

  const optionsSelectGraficos = [
    { value: 'ga', label: 'Gráfico Arapongas'},
    { value: 'gl', label: 'Gráfico Londrina'},
    { value: 'gm', label: 'Gráfico Maringá'},
    { value: 'todos', label: 'Todos os Gráficos'}
  ]

  
  const [valueZoom, setValueZoom] = useState("100%")
  const [porcent, setPorcent] = useState(100);
  const prevPorcentRef = useRef();
  const prevPorcent = prevPorcentRef.current;

  useEffect(() => {
    prevPorcentRef.current = porcent;
    if(prevPorcent < porcent){
      prop.ChangeW(prop.valueW + 80)
      prop.ChangeH(prop.valueH + 40)
      prop.ChangeHW(prop.valueHW + 20)
      setValueZoom(porcent + "%")
      

    }

    if(prevPorcent > porcent){
      prop.ChangeW(prop.valueW - 80)
      prop.ChangeH(prop.valueH - 40)
      prop.ChangeHW(prop.valueHW - 20)
      setValueZoom(porcent + "%")

    }

  }, [porcent]);

  return (
    <div className="navbar" style={{width: prop.width}}>
      <div className="wrapper">
        <div className="logo">
          <Link to="/home" style={{ textDecoration: "none" }}>
            <img src={logo} alt="testsss" width={60} height={60} />
          </Link>
        </div>
         




      </div>
    </div>
  )
}

export default NavBar
