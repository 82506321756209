import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';

import './home.scss';
import logo from "./logo.png";

import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import CardPedido from '../../components/card/cardPedido'
import Button from '../../components/button/Button';

import PopUpAlerta from '../../components/popUpAlerta/PopUpAlerta'
import PopUpConfirmaRetorno from '../../components/popUpVazio/PopUpVazio'
import { atualizaStatusRetorno } from '../../api/retornos';


const Check = () => {
  const { idMotorista } = useParams(); 
  const [retornos, setRetornos] = useState([]);
  const [popUpConfirmaRetorno, setPopUpConfirmaRetorno] = useState(false)
  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);

  const [bufferRetorno, setBufferRetorno] = useState("");

  useEffect(() => {
    const fetchRetornos = async () => {
      try {
        atualizaRetornos(idMotorista, setRetornos);
      } catch (error) {
        console.error("Erro ao buscar retornos:", error);
      }
    };

    fetchRetornos();
  }, [idMotorista]);

  const handleConfirmaRetorno = (retorno) => {
    setBufferRetorno(retorno);
    setPopUpConfirmaRetorno(true);
  }

  const handleRetornou = async (id) => {
    try {
      await atualizaStatusRetorno(id, "motorista");
      await atualizaRetornos(idMotorista, setRetornos);
      setPopUpConfirmaRetorno(false);
    } catch (error) {
      setPopUpConfirmaRetorno(false);
      setConteudoPopUp(`Erro ao lidar com retorno de ID ${id}. Tente novamente.`);
      setPopUpAlerta(true);
      console.error("Erro ao lidar com retorno:", error);
    }
  }

  return (
    <div className="home">
      <div className="homeCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"} acesso={{userId: idMotorista}} />
        <Sidebar />
        <div className="content">
          <div>
            {retornos && Array.isArray(retornos) && retornos != undefined &&  retornos.length > 0 ? (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {retornos &&
                    Array.isArray(retornos) &&
                    retornos.map((retorno, index) => (
                      <CardPedido 
                        key={index}
                        pedido={retorno.numero_pedido || "N/A"}
                        motorista={retorno.nomeEmpresa}
                        endereco={retorno.parada}
                        retorno={retorno.retorno}
                        onClick={() => handleConfirmaRetorno(retorno)}
                      />
                    ))}
              </div>
            ) : (
              <p>Não foram encontrados retornos em aberto.</p>
            )}
            <img className="logo" src={logo} style={{ opacity: 0.2, maxWidth: '100%' }} />
          </div>


          <PopUpConfirmaRetorno trigger={popUpConfirmaRetorno} setTrigger={setPopUpConfirmaRetorno} light={true} >
            {
                <div style={{ color: "#5a595c" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <strong>{"PEDIDO "}</strong>
                    </div>
                    <div style={{ width: 'auto' }}>
                        <div style={{ display: "flex", alignItems: "center", height: 'auto' }}>
                          <StorefrontIcon style={{ marginRight: "5px" }} />
                          {bufferRetorno.nomeEmpresa}
                        </div>
                        <div style={{ display: "flex", alignItems: "center", height: 'auto' }}>
                          <PlaceOutlinedIcon fontSize="small" style={{ marginRight: "5px" }} />
                          {bufferRetorno.parada}
                        </div>
                    </div>
                    <br />
                    <div style={{color: "#5a595c" , marginBottom:"10px"}}>
                      <p>{"Você deseja confirmar esse retorno?"}</p>
                    </div>
                    <div>
                      < Button
                        text='Sim'
                        width='120px' 
                        borderRadius='8px' 
                        border='black' 
                        background='rgb(66, 175, 60)' 
                        color='white' 
                        onClick={()=>{ handleRetornou(bufferRetorno._id)} }
                      /> 
                      < Button
                        text='Não'
                        width='120px' 
                        borderRadius='8px' 
                        border='black' 
                        background='#c84333' 
                        color='white' 
                        onClick={()=>{setPopUpConfirmaRetorno(false)}}
                      /> 
                    </div>

                </div>
            }
          </PopUpConfirmaRetorno>

          <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
          {
            <div>
              {conteudoPopUp}
            </div>
          }
          </PopUpAlerta>
        </div>
      </div>
    </div>
  );
};

const atualizaRetornos = async (idMotorista, setRetornos) => {
  try {
    const response = await axios.get(`/retornos/getRetornosDoMotorista`, {
      params: { dados: idMotorista }
    });
    setRetornos(response.data);
  } catch (error) {
    console.error("Erro ao atualizar retornos:", error);
  }
}

export default Check;
