/* SUMÁRIO
    enviaMensagem:Envia mensagem ao motorista informando seu saldo
*/

import axios from 'axios'

const baseURL = '/saldo'

const saldoApi = axios.create({
  baseURL: baseURL,
})

export const enviaMensagem = async (value) => {
    //Envia mensagem ao motorista informando seu saldo

    const res = await saldoApi.get('/enviaMensagemPorCPF',  {params: value})

    return res
}