import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './app.scss';
import Check from './pages/check/Check';
import Saldo from './pages/saldo/saldo';
import Repasse from './pages/repasse/Check';
import { ProtectedLayout } from './components/protectedRoutes/ProtectedLayout';
import { HomeLayout } from './components/protectedRoutes/HomeLayout';
import { ThemeCtx } from './utils/context/themeCtx';
import { ReactQueryDevtools } from 'react-query/devtools';

const App = () => {
  const { darkMode } = useContext(ThemeCtx);

  return (
    <div className={darkMode ? 'app dark' : 'app'}>
      <Routes>
        <Route>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/home" element={<Check />} />
          <Route path="/saldo" element={<Saldo />} />
          <Route path="/repasse/:id" element={<Repasse />} />
          <Route path="/:idMotorista" element={<Check />} />
        </Route>

        <Route>
          <Route path="*" element={<Navigate replace to="/home" />} />
        </Route>
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  );
}

export default App;
